<template>
  <div>
    <h1 class="title">Probably correct author records</h1>
    <table class="table" v-if="done">
      <thead>
        <tr>
          <th>id</th>
          <th>Call #</th>
          <th>Title</th>
          <th>Author</th>
          <th>Metadata</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(book, i) in books" v-bind:key="book.id">
          <td>{{book.id}}</td>
          <td>{{book.attributes.call_number }}</td>
          <td>{{book.attributes.title }}</td>
          <td>{{book.attributes.author }}</td>
          <td>
            <span v-if="book.attributes.author_is_editor" class="tag small is-warning">Editor</span>
            <span v-if="book.attributes.author_is_institution" class="tag small is-secondary">Institution</span>
          </td>
          <td> 
            <button :disabled="updating" class="button is-small is-secondary" @click="toggleInstitution(i)">Toggle institution</button>
            <button :disabled="updating" class="button is-small is-warning"  @click="toggleEdited(i)">Toggle edited</button>
            <button :disabled="updating" class="button is-small is-secondary" @click="cleanPartnership(i)">Clean patnership</button>
       
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <img src="@/assets/ajax-loader.gif"/>
    </div>
  </div>

</template>
<script>

export default {
  data () {
    return {
      updating: false,
      done: false,
      fetching: true,
      paginationMeta: null,
      totalPages: null,
      books: null,
      restrictSection: null
    }
  },
  methods : {
    toggleEdited(bookIndex) {
      this.updating = true
      let url = '/v1/books/' + this.books[bookIndex].id
      this.axios.put(url, { author_is_editor: !this.books[bookIndex].author_is_editor })
        .then((response) => {
          if (response.status === 200) {
            this.books.splice(bookIndex, 1, response.data.data)
          }
          this.updating = false
        })
      
    },
    toggleInstitution(bookIndex) {
      this.updating = true
      let url = '/v1/books/' + this.books[bookIndex].id
      this.axios.put(url, { author_is_institution: !this.books[bookIndex].author_is_institution })
        .then((response) => {
          if (response.status === 200) {
            this.books.splice(bookIndex, 1, response.data.data)
          }
          this.updating = false
        })
      
    },
    cleanPartnership(bookIndex) {
      this.updating = true
      let url = '/v1/housekeeping/' + this.books[bookIndex].id + '/clean_commas'
      this.axios.get(url)
        .then((response) => {
          if (response.status === 200) {
            this.books.splice(bookIndex, 1, response.data.data)
          }
          this.updating = false
        })
    },
    addComma(bookIndex) {
      let editor = false
      let institution = false
      if (this.books[bookIndex].attributes.author.match(/^(ed\.|Red\.|sud\.|edited by|)/i)) {
        editor = true
        this.books[bookIndex].attributes.author = this.books[bookIndex].attributes.author.replace(/^(ed\.|Red\.|sud\.|edited by|)\s*/i, '')
      }
      let split = this.books[bookIndex].attributes.author.split(/\s/)
      let newAuthor = split.pop()  + ', ' + split.join(' ')
      console.log('new author would be ' + newAuthor)
    }
  },
  mounted () {

    this.axios.get("/v1/housekeeping/probably_healthy_authors")
      .then((response) => {
        this.books = response.data.data
        this.done = true
      })
  }
}
</script>
